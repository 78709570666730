.container-f {
  background-image: url("./assets/images/fortune/bg-fortune.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  font-family: "Niramit", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.title-f {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;

  background-image: url("./assets/images/fortune/ui-box-fortune.png");
  background-size: cover;
  background-position: center;

  padding: 32px;
  border-radius: 10px;

  z-index: 2;
}
.astrologer-card {
  background-color: #231722;

  background-image: url("./assets/images/fortune/ui-border-fortune.png");
  background-size: cover;
  background-position: center;

  border: none;
  border-radius: 10px;

  z-index: 0;
}

.card-img-top {
  border-radius: 10px 10px 0 0;

  z-index: 1;
}

.name-banner {
  background-image: url("./assets/images/fortune/ui-box-fortune-name.png");
  background-size: cover;
  background-position: center;

  color: #000000;
  padding: 5px;
  border-radius: 5px;

  z-index: 1;
}

.astrologer-name {
  font-size: 0.8rem;
  font-weight: bold;

  z-index: 2;
}

.particle-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Push the canvas to the background */
  pointer-events: none; /* Make the canvas non-interactive */
}

.astrologer-card-s {
  background-color: #231722;

  background-image: url("./assets/images/fortune/ui-border-fortune.png");
  background-size: cover;
  background-position: center;
  width: 140px;

  border: none;
  border-radius: 10px;
}

.card-img-top-s {
  border-radius: 10px 10px 0 0;
  width: 140px;
}

.name-banner-s {
  background-image: url("./assets/images/fortune/ui-box-fortune-name.png");
  background-size: cover;
  background-position: center;

  color: #000000;
  padding: 5px;
  border-radius: 5px;
}

.astrologer-name-s {
  font-size: 0.8rem;
  font-weight: bold;

  z-index: 2;
}

.modal-overlay {
  height: 100vh; /* เต็มความสูงของหน้าจอ */

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  background-color: #231722;
  border: solid 2px #eebf64;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  color: #fff; /* สีตัวอักษร */
  font-family: "Niramit", sans-serif;
}

.close-button-f {
  position: absolute;

  width: 35px;
  height: 35px;



  top: 10px;
  right: 10px;

  background-image: url("./assets/images/fortune/ui-close.png");
  background-size: cover;
  background-position: center;
  

  border-radius: 100%;
  border: none;
  font-size: 34px;
  color: #fff;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;
}



.modal-image {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.btn-primary-f {
  background: linear-gradient(
    135deg,
    #6c1010,
    #300000,
    #6c1010
  ); /* พื้นหลังม่วง-ดำ */
  border: solid 2px #eebf64;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 15px;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
}

.p-name {
  font-size: 14px;
}

.p-des {
  font-size: 11px;
}
