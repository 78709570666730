/* Layout Structure */

.container-taros {
  position: relative; /* ใช้สำหรับ pseudo-element */
  display: flex;
  flex-direction: column;
  height: 100vh; /* เต็มความสูงของหน้าจอ */
  overflow: hidden;
  font-family: "Kanit", sans-serif;

  background-image: url("./assets/images/talos/bgtatosv1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.container-taros-payment {
  padding-left: 40px;

  font-family: "Kanit", sans-serif;
  
}

.container-taros p {
  font-size: 18px;
}
.container-taros h1 {
  font-size: 28px;
}
.container-taros h6 {
  font-size: 28px;
}

.container-taros i{
  font-size: 16px;
  color: #f5f5f5;
}

.container-taros::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75); /* เพิ่มความมืด (50%) */
  z-index: 0; /* ทำให้โอเวอร์เลย์อยู่ด้านบน */
  pointer-events: none; /* ป้องกันการคลิกที่โอเวอร์เลย์ */
}

.container-taros > * {
  position: relative; /* ทำให้เนื้อหาอยู่เหนือโอเวอร์เลย์ */
  z-index: 2;
}

.header {
  flex: 0 0 10%; /* สูง 10% */
  display: flex;
  align-items: center;
  justify-content: center;
  top: -60px;
}

.header h1 {
  color: #e0dcdc;

  font-family: "Niramit", sans-serif;
  font-weight: 500;
  font-style: normal;
}

/* Middle Section (Cards) */
.deck-container {

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px 0 0 50px;
}

.topshowreal {
  margin-top: 20px;
}

.deck-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* แบ่งการ์ดเป็น 13 ใบต่อแถว */
  gap: 10px; /* ระยะห่างระหว่างไพ่ */
  justify-content: center;
  align-items: center;
}

.card {
  display: flex; /* ใช้ Flexbox */
  flex-direction: column; /* จัดการองค์ประกอบในแนวตั้ง */
  align-items: center; /* จัดให้อยู่ตรงกลางแนวแกน X */
  justify-content: center; /* จัดให้อยู่ตรงกลางแนวแกน Y */

  margin-left: -55px;
  margin-top: -100px;

  width: 90px;
  height: 140px;
  background-color: #ffffff;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(235, 232, 236, 0.171);
  transition: transform 0.01s ease, box-shadow 0.01s ease;
}

/* .card:hover {
    transform: translateY(-5px) scale(8.05);
    box-shadow: 0px 4px 8px rgba(255, 255, 255, 0.904);
  }*/

.card-back {
  display: flex; /* ใช้ Flexbox */
  flex-direction: column; /* จัดการองค์ประกอบในแนวตั้ง */
  align-items: center; /* จัดให้อยู่ตรงกลางแนวแกน X */
  justify-content: center; /* จัดให้อยู่ตรงกลางแนวแกน Y */

  width: 95%;
  height: 95%;
  background-image: url("./assets/images/talos/backcard.png");
  background-size: cover; /* ให้ภาพครอบคลุมพื้นที่ทั้งหมด */
  background-position: center; /* จัดตำแหน่งภาพให้อยู่ตรงกลาง */
  border-radius: 2px;
  margin: 2px;
}

.card-confirm {
  background-image: url("./assets/images/talos/backcard.png");

  width: 120px;
  height: 210px;
  border-radius: 3px;

  background-size: cover; /* ให้ภาพครอบคลุมพื้นที่ทั้งหมด */
  background-position: center; /* จัดตำแหน่งภาพให้อยู่ตรงกลาง */
  border-radius: 2px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.993);
  border: 2px solid rgba(192, 192, 192, 0.9);
  margin-bottom: 20px;
}

.card-front {
  width: 200px;
  height: 350px;

  border-radius: 2px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.993);
  border: 2px solid rgba(192, 192, 192, 0.9);
  margin-bottom: 20px;
}
.card-front-2 {
  width: 240px;
  height: 420px;

  border-radius: 2px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.993);
  border: 2px solid rgba(192, 192, 192, 0.9);
  margin-bottom: 20px;
}
.card-front-3 {
  width: 150px;
  height: 290px;

  border-radius: 2px;
  box-shadow: 0px 0px 50px rgba(255, 255, 255, 0.993);
  border: 2px solid rgba(192, 192, 192, 0.9);
  margin-bottom: 10px;
}

.footers {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 50px;
}

.button-container {
  display: flex; /* ใช้ Flexbox */
  justify-content: space-between; /* กระจายปุ่มซ้าย-ขวา */
  width: 80%; /* ให้เต็มความกว้างของ parent */
  max-width: 600px; /* กำหนดความกว้างสูงสุด */
  gap: 20px; /* ระยะห่างระหว่างปุ่ม */
}

.selected-card {
  margin-bottom: 10px;
  text-align: center;
}

.selected-confirm {
  display: flex;
  color: #e0dcdc;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  text-align: center;
  padding: 10px;
}

.shuffle-button {
  padding: 10px 20px;
  font-size: 20px;

  width: 500px;
  height: 50px;

  background: linear-gradient(135deg, #6c4575, #270c31); /* พื้นหลังม่วง-ดำ */
  color: white;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  /* เงาสีเงิน */
  box-shadow: 0px 2px 4px rgba(227, 204, 233, 0.356),
    /* เงาสีเงินภายนอก */ inset 0px 0px 8px rgba(59, 23, 70, 0.5); /* เงาสีขาวด้านใน */

  /* เส้นขอบสีเงิน */
  border: 2px solid rgba(192, 192, 192, 0.9);
}

.clickable-text {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  color: #fcfcfc;
}

.buyproduct-button {
  padding: 5px 20px;
  font-size: 18px;

  margin: 20px;

  background: linear-gradient(135deg, #6c4575, #270c31); /* พื้นหลังม่วง-ดำ */
  color: rgb(255, 253, 158);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  /* เงาสีเงิน */
  box-shadow: 0px 2px 4px rgba(227, 204, 233, 0.356),
    /* เงาสีเงินภายนอก */ inset 0px 0px 8px rgba(59, 23, 70, 0.5); /* เงาสีขาวด้านใน */

  /* เส้นขอบสีเงิน */
  border: 2px solid rgba(192, 192, 192, 0.9);
}
.repage-button {
  padding: 5px 20px;
  font-size: 18px;

  background: linear-gradient(135deg, #6c4575, #270c31); /* พื้นหลังม่วง-ดำ */
  color: rgb(209, 209, 209);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  /* เงาสีเงิน */
  box-shadow: 0px 2px 4px rgba(227, 204, 233, 0.356),
    /* เงาสีเงินภายนอก */ inset 0px 0px 8px rgba(59, 23, 70, 0.5); /* เงาสีขาวด้านใน */

  /* เส้นขอบสีเงิน */
  border: 2px solid rgba(192, 192, 192, 0.9);
}

.particle-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Push the canvas to the background */
  pointer-events: none; /* Make the canvas non-interactive */
}

.boxDes {
  font-size: 14px;
  font-weight: 300;
  background: #301833; /* พื้นหลังม่วง-ดำ */
  color: white;

  border-radius: 8px;
  border: 2px solid rgba(28, 13, 31, 0.9);

  padding: 10px 25px 0 25px;
  margin: 0 100px 0 100px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .header h1 {
    font-size: 24px;
  }

  .deck-container {
    padding-left: 37px;
  }
  

  .deck-grid {
    grid-template-columns: repeat(8, 1fr); /* ลดจำนวนคอลัมน์ในหน้าจอเล็ก */
    gap: 5px;
  }

  .card {
    width: 65px;
    height: 105px;
    border-radius: 3px;

    margin-left: -40px;
    margin-top: -80px;
  }
  .card-confirm {
    width: 120px;
    height: 210px;
    border-radius: 3px;
  }
  .card-front {
    width: 120px;
    height: 200px;
  }

  .shuffle-button {
    font-size: 18px;
    width: 300px;
    height: 50px;
    border-radius: 15px;
  }

  .button-container {
    max-width: 300px;
  }

  .buyproduct-button {
    font-size: 16px;
  }

  .repage-button {
    font-size: 16px;
  }

  .container-taros p {
    font-size: 14px;
  }
  .container-taros h1 {
    font-size: 24px;
  }
  .container-taros h6 {
    font-size: 18px;
  }

  .container-taros-payment{
    padding-left: 35px;
  }

  .boxDes{
    margin: 0 24px 0 24px;
  }


  .container-taros i{
    font-size: 12px;
    color: #f5f5f5;
  }
}
