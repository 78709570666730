.container-taros {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
  }
  
  .background-card {
    position: relative;
    width: 300px;
    height: 500px;
    background: url("./assets/images/talos/tarot-bg-home.png") no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.993);

    border-radius: 14px;
    border: 2px solid rgba(28, 13, 31, 0.9);
  }
  
  .content-overlay {
    font-family: "Niramit", sans-serif;
    font-weight: 100;
    font-style: normal;

    position: absolute;
    text-align: center;
    color: white;

    

    bottom: 15%;
  }

  .particle-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Push the canvas to the background */
    pointer-events: none; /* Make the canvas non-interactive */
  }
  
  

  
  .open-card-button {
    padding: 5px 40px;
    font-size: 15px;
    
    background: linear-gradient(135deg, #6c4575, #270c31); /* พื้นหลังม่วง-ดำ */
    color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
  
    /* เงาสีเงิน */
    box-shadow: 
      0px 2px 4px rgba(227, 204, 233, 0.356), /* เงาสีเงินภายนอก */
      inset 0px 0px 8px rgba(59, 23, 70, 0.5); /* เงาสีขาวด้านใน */
    
    /* เส้นขอบสีเงิน */
    border: 2px solid rgba(192, 192, 192, 0.9);

    margin-top: 15px;
  }
  
  .open-card-button:hover {
    background-color: #5c2e2e;
    transform: scale(1.05);
  }
  