
body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.containers {
  font-family: 'Kanit', sans-serif;
  
}
.signup-container{
  font-family: 'Kanit', sans-serif;
  background: #ffffffb0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px; /* กำหนดขนาดสูงสุด */
  margin: 200px auto; /* จัดให้อยู่ตรงกลางและเพิ่ม margin เพื่อเว้นขอบบนล่าง */
  box-sizing: border-box;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-image {
  width: 65%;
  max-width: 400px;
  margin-bottom: 0px;
}

.main-image {
  width: 100%;
  max-width: 400px;
  margin-bottom: 10px;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* ครอบคลุมทั้งความสูงของหน้าจอ */
  font-family: 'Kanit', sans-serif;
  background: linear-gradient(to bottom, #ff90b5, #fff1a0);
  padding: 10px;
}

/*.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
}*/

.containers {
  background: #ffffff8a;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  box-sizing: border-box;
}
.pageResultInfo{
 margin-top: 30px;
}

.canvas-wrapper {
  width: 60%; /* ความกว้าง 60% ของ container */
  margin: 0 auto; /* จัดให้อยู่ตรงกลาง */
}

canvas {
  width: 100%; /* ให้ canvas ปรับขนาดตาม wrapper */
  height: auto; /* คงอัตราส่วนภาพ */
}



.date-group {
  display: flex;
  justify-content: space-between;
}

select {
  width: calc(100% - 10px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.date-group select {
  width: 30%;
  margin-right: 5px;
}

.date-group select:last-child {
  margin-right: 0;
}


footer {
  background: #fff;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #888;
  border-top: 1px solid #ccc;
  position: fixed;
  bottom: 0;
  left: 0;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

.payment-methods {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.card-details {
  margin-top: 10px;
}

.card-details input {
  width: calc(100% - 50px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.expiry-cvv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.expiry-cvv input {
  width: 40%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.qr-code {
  margin-top: 20px;
}

.qr-code img {
  width: 80%;
  border-radius: 10px;
}

.read-more-button {
  color: #1E90FF;
  border: none;
  background: none;
  padding: 0;
  margin-bottom: 10px;
}


.form-check {
  margin-left: 0;
  display: flex;
  color: grey;
}

.form-check-input {
  margin-right: 20px;
}

.form-check-label {
  margin: 0;
}

.label-wish{
  font-size: 18px;
  text-align: left;
  display: flex;
}



.custom-button {
  color: #ffffff;
  border: none; /* ลบขอบของปุ่มเดิม */
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: 20px;
  font-weight: 500;

}

.progress-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  font-size: 14px; /* ปรับขนาดฟอนต์เป็น 14px เพื่อให้อ่านง่าย */
}

.step {
  width: 18%;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: #ccc; /* สีจางสำหรับขั้นตอนที่ยังไม่ถึง */
  border-bottom: 3px solid #ccc; /* เส้นล่างจางสำหรับขั้นตอนที่ยังไม่ถึง */
  font-size: 12px; /* ขนาดฟอนต์หลัก */
}

.step.active {
  color: #000; /* สีเข้มสำหรับขั้นตอนปัจจุบัน */
  border-bottom: 3px solid #007bff; /* เส้นล่างสีฟ้าสำหรับขั้นตอนปัจจุบัน */
}

.signup-container {
  font-family: 'Kanit', sans-serif;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 800px; /* ขนาดที่ต้องการ */
  margin: 50px auto;
  box-sizing: border-box;
}
.signup-container label{
  font-size: 14px;
}

.signup-container .header {
  text-align: center;
  margin-bottom: 20px;
}

.signup-container .steps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.signup-container .steps div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #888;
  font-size: 14px;
}

.signup-container .steps div.active {
  color: #000;
  font-weight: bold;
}

.signup-container .steps div span {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #888;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.signup-container .steps div.active span {
  background: #ff90b5;
  border-color: #ff90b5;
}

.signup-container .form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.signup-container .form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.signup-container input, .signup-container select {
  width: calc(100% - 10px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px; /* ขนาดฟอนต์ที่กำหนดสำหรับการกรอกข้อมูล */
}

.signup-container .form-group.left, .signup-container .form-group.right {
  width: 48%; /* ปรับความกว้างสำหรับ input 2 ช่อง */
}

.signup-container button {
  background: #FF5E78;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 10px;
  font-size: 16px; /* ขนาดฟอนต์สำหรับปุ่ม */
}

.signup-container button:hover {
  background: #FF4E66;
}

.signup-container .phone-otp, .signup-container .otp-confirm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signup-container .phone-otp input, .signup-container .otp-confirm input {
  width: 60%;
}

.signup-container .phone-otp button, .signup-container .otp-confirm button {
  width: 35%;
  background-color: #ddd;
  border: none;
  color: #333;
  padding: 10px;
  font-size: 16px;
}

.signup-container .otp-confirm button {
  background-color: #888;
  color: #fff;
}

.signup-container .address {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


.signup-container .address .form-group {
  width: 48%;
}

.signup-container .address .country {
  width: 100%;
}

.signup-container .button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.signup-container .custom-button {
  background: linear-gradient(to bottom, #e7a3bd, #e7a3bd);
  color: #383434;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background 0.3s ease;
  font-size: 16px;
  font-weight: 500;
}

.bg-infun{
  background-image: url("./assets/images/talos/bgtatosv1.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}

.text-table-items-paid{
  font-size: 14px;
}














