.container-lage {
    padding: 20px;
    font-family: Arial, sans-serif;

    background-color: #1a191b;
  }
  
  h1 {
    text-align: center;
    color: antiquewhite;
    margin-bottom: 20px;
  }

  i{
    font-size: 10px;
    color: rgb(207, 207, 207);
  }
  
  .tarot-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .tarot-item {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    width: 280px;
    text-align: center;
    background: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .tarot-image {
    width: 100%;
    height: auto;
  }
  
  .tarot-details {
    padding: 10px;
  }
  
  .tarot-details h3 {
    margin: 10px 0;
    font-size: 18px;
  }
  
  .tarot-details p {
    font-size: 14px;
    color: #555;
  }
  